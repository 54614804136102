import { ThrowStmt, Xliff } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';
import { OfferDetailsComponent } from './../offer-details/offer-details.component';
import { EditOfferComponent } from './edit-offer/edit-offer.component';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss'],
})
export class OffersComponent implements OnInit {
  offers= []
  constructor( private db: AngularFirestore, private modalController: ModalController) 
  { 
    this.getOffers()
  }

  ngOnInit() {}

  getOffers(){
    this.db.collection('oferte').snapshotChanges()
    .subscribe(
      (res)=>{
        this.offers=[]
        res.forEach(
          (c)=>{
            let temp = c.payload.doc.data()
            temp['id']= c.payload.doc.id
            this.offers.push(temp)
          }
        )
      }
    )
  }

  async showOfferDetails(offer){
    const modal = await this.modalController.create({
      component: OfferDetailsComponent,
      componentProps: {
        offer: offer
      }
    });
    return await modal.present();
  }

  async editOffer(offer){
    console.log('Editing offer', offer)
    const modal = await this.modalController.create({
      component: EditOfferComponent,
      cssClass: 'edit-modal',
      componentProps:{
        offer: offer
      }
    });
    return await modal.present();
  }

  duplicateOffer(offer){
    let newOffer = offer
    newOffer['date'] = new Date()
    this.db.collection('oferte').add(newOffer)
    .then(
      ()=>{
        alert('Offer duplicated!')
      }
    )
  }

}
