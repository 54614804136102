import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { HomepageComponent } from './homepage/homepage.component';
import { NewofferComponent } from './newoffer/newoffer.component';
import { ProductsComponent } from './products/products.component';
import { OffersComponent } from './offers/offers.component';
import { YamlGeneratorComponent } from './tools/yaml-generator/yaml-generator.component';
import { ToolsComponent } from './tools/tools.component';
import { InventoryComponent } from './inventory/inventory.component';


const routes: Routes = [
  {
    path: '',
    // loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    component: HomepageComponent
  },
  {
    path: 'home',
    component: HomepageComponent
  },
  {
    path: 'newoffer',
    component: NewofferComponent
  },
  {
    path: 'products',
    component: ProductsComponent
  },
  {
    path: 'offers',
    component: OffersComponent
  },
  {
    path: 'tools',
    component: ToolsComponent,
    // loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'yamlgenerator',
    component: YamlGeneratorComponent
  },
  {
    path: 'inventory',
    component: InventoryComponent
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
