import { ngfModule, ngf } from 'angular-file'
import { Component, OnInit, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClient, HttpClientModule, HttpRequest, HttpResponse, HttpEvent} from '@angular/common/http'
import { Subscription } from 'rxjs'

import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';

@Component({
  selector: 'app-yaml-generator',
  templateUrl: './yaml-generator.component.html',
  styleUrls: ['./yaml-generator.component.scss'],
})
export class YamlGeneratorComponent implements OnInit {
  postUrl = '...'
  myFormData: FormData // populated by ngfFormData directive
  httpEvent: HttpEvent<{}>
  csvRecords: any[] = [];
  inputs: any[] = []
  header = false;
  ymlOutput = {binary_sensor: [], cover: [], light: []}
  ymlOutputText = ''
  adamNo = 1
  covers = []
  lights = []
  projectFile

  constructor(public HttpClient: HttpClient, private ngxCsvParser: NgxCsvParser) { }

  ngOnInit() {}

  fileUploaded(files){
    console.log('Files uploaded', files)
    // Parse the file you want to select for the operation along with the configuration
    this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {

        console.log('Result', result);
        this.csvRecords = result;
        this.createInputs()
        this.parseCsv(result)
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      })

  }

  saveProject(){
    const data = {
      adamNo : this.adamNo,
      csvRecords: this.inputs
    }

    const dlink: HTMLAnchorElement = document.createElement('a');
    dlink.download = 'myfile.json' // the file name
    const myFileContent = JSON.stringify(data)
    dlink.href = 'data:text/plain;charset=utf-16,' + myFileContent
    dlink.click() // this will trigger the dialog window
    dlink.remove()
    
  }

  loadProject(files){
    let _self = this
    console.log('Project file', files)
    var reader = new FileReader();
    reader.readAsText(files[0], "UTF-8");
    reader.onload = function (evt) {
      const prjData = JSON.parse( evt.target.result.toString() )
      _self.adamNo = prjData['adamNo']
      _self.inputs = prjData['csvRecords']
    }
    reader.onerror = function (evt) {
        console.log('error reading file');
    }
  }

  deleteInput(i){
    if (confirm('Esti sigur ca vrei sa stergi ' + i +'?')){
      this.inputs.splice(i, 1)
    }
  }

  addInput(){
    this.inputs.push([])
  }


  createInputs(){
    this.csvRecords.forEach(
      (el, i)=>{
        if ( el[3] ){
          const inp = el[1].split('_')
          this.inputs[i] = {
                            comment: el[0],
                            name: 'ha' + this.adamNo + '_di_' +  inp[2],
                            type: el[4],
                            outName: el[3],
                            outComment: el[2],
                            timer: 20
                           }
        }
      }
    )
    // delete(this.inputs[0])
    console.log(this.inputs)
  }

  generateYaml(){
    this.ymlOutput = {binary_sensor: [], cover: [], light: []}

    this.gatherCovers()
    if ( this.covers.length > 0){
      for (const key in this.covers) {
        if (Object.prototype.hasOwnProperty.call(this.covers, key)) {
          this.ymlOutput['cover'].push(this.generateCoverYaml(this.covers[key]))
        }
      }
    }
    
    this.inputs.forEach(
      (inp, i) => {
        switch (inp['type']) {
          case 'switch':
            this.ymlOutput['binary_sensor'].push(this.generateSwitchYaml(inp))
            break
          case 'light':
            this.ymlOutput['light'].push(this.generateLightYaml(inp))
            this.ymlOutput['binary_sensor'].push(this.generateLightInputYaml(inp))
            break
          case 'jaluzea':
            this.ymlOutput['binary_sensor'].push(this.generateCoverInputYaml(inp))
            break
          default:
            break
        }
      }
    )

    for (const key in this.ymlOutput) {
      if (Object.prototype.hasOwnProperty.call(this.ymlOutput, key)) {
        const element = this.ymlOutput[key]
        this.ymlOutputText += '\n' + key + ':'
        this.ymlOutputText +=  element + '\n'
      }
    }

  }

  // Lights
  generateLightYaml(inp){
    let yml = '\n' + '  # ' + inp['comment'].replace('\n', ' | ') + '\n'
    yml += '  - platform: binary' + '\n'
    // input
    let inpArray = inp['name'].split('_')
    // console.log('inp', inp)
    yml += '    name: "' + inp['lightName'] + '"' +  '\n'
    yml += '    id: "' + inp['lightName'] + '"' +  '\n'
    yml += '    output: ' + inp['outName'] + ' # ' + inp['outComment'] +  '\n'
    return yml
  }

  generateLightInputYaml(inp){
    let yml = '\n' + '  # ' + inp['comment'].replace('\n', ' | ') + '\n'
    yml += '  - platform: gpio' + '\n'
    let inpArray = inp['name'].split('_') // input
    yml += '    name: "' + inp['name'] + '"' +  '\n'
    yml += '    id: "' + inp['name'] + '"' +  '\n'
    yml += '    pin: ' + '\n'
    yml += '      pcf8574: ha' + this.adamNo + '_hub_in ' +  '\n'
    yml += '      number: ' + (inpArray[2] - 1) +  '\n'
    yml += '      mode: INPUT ' +  '\n'
    yml += '      inverted: True ' +  '\n'
    yml += '    on_state: ' +  '\n'       // on_press, on click
    yml += '      then: ' +  '\n'
    yml += '        - light.toggle: ' + inp['lightName'] + ' # ' + inp['outComment'] +  '\n'
    return yml
  }

  // Lights

  generateSwitchYaml(inp){
    let yml = '\n' + '  # ' + inp['comment'].replace('\n', ' | ') + '\n'
    yml += '  - platform: gpio' + '\n'
    // input
    let inpArray = inp['name'].split('_')
    // console.log('inp', inp)
    yml += '    name: "' + inp['name'] + '"' +  '\n'
    yml += '    id: "' + inp['name'] + '"' +  '\n'
    yml += '    pin: ' + '\n'
    yml += '      pcf8574: ha' + this.adamNo + '_hub_in ' +  '\n'
    yml += '      number: ' + (inpArray[2] - 1) +  '\n'
    yml += '      mode: INPUT ' +  '\n'
    yml += '      inverted: True ' +  '\n'
    yml += '    on_switch: ' +  '\n'       // on_press, on click
    yml += '      then: ' +  '\n'
    yml += '        - switch.toggle: ' + inp['outName'] + ' # ' + inp['outComment'] +  '\n'
    return yml
    console.log('generateSwitchYaml', yml)
  }

  gatherCovers(){
    this.covers = []
    this.inputs.forEach(
      (inp) => {
        if(inp['type'] === 'jaluzea' && inp['coverName']){
          if(!this.covers[inp['coverName']]){
            this.covers[inp['coverName']] = {}
          }
          this.covers[inp['coverName']][inp.coverAction] = inp
        }
      }
    )
    console.log('[gatherCovers] covers ', this.covers)
  }

  generateCoverInputYaml(inp){
    const coverID = 'cover_' + this.ymlOutput['cover'].length
    let yml = '\n' + '  # ' + inp['comment'].replace('\n', ' | ') + '\n'
    yml += '  - platform: gpio' + '\n'
    // input
    let inpArray = inp['name'].split('_')
    // console.log('inp', inp)
    yml += '    name: "' + inp['name'] + '"' +  '\n'
    yml += '    id: "' + inp['name'] + '"' +  '\n'
    yml += '    pin: ' + '\n'
    yml += '      pcf8574: ha' + this.adamNo + '_hub_in ' +  '\n'
    yml += '      number: ' + (inpArray[2] - 1) +  '\n'
    yml += '      mode: INPUT ' +  '\n'
    yml += '      inverted: True ' +  '\n'
    yml += '    on_press: ' +  '\n'       // on_press, on click
    yml += '      then: ' +  '\n'
    yml += '        - lambda: | ' + '\n'
    yml += '            if (id(' + inp.coverName + ').current_operation == COVER_OPERATION_IDLE)' + '\n'
    yml += '              {' + '\n'
    yml += '                id(' + inp.coverName + ').' + inp.coverAction + '();' + '\n'
    yml += '              }' + '\n'
    yml += '            else' + '\n'
    yml += '              {' + '\n'
    yml += '                id(' + inp.coverName + ').stop();' + '\n'
    yml += '              }' + '\n'
    return yml
    console.log('generateCoverInputYaml', yml)
  }

  generateCoverYaml(cover){
    let yml = '\n' + '  # ' + cover.open['comment'].replace('\n', ' | ') + '\n'
    yml += '  - platform: time_based' + '\n'
    yml += '    name: "' + cover.open.coverName + '"' +  '\n'
    yml += '    id: ' + cover.open.coverName +  '\n'
    yml += '    open_action:' + '\n'
    yml += '      - switch.turn_on: ' + cover.open.outName + '\n'
    yml += '    open_duration: ' + cover.open.timer + 'sec' + '\n'
    yml += '    close_action:' + '\n'
    yml += '      - switch.turn_on: ' + cover.close.outName + '\n'
    yml += '    close_duration: ' + cover.close.timer + 'sec' + '\n'
    yml += '    stop_action: ' + '\n'
    yml += '      - switch.turn_off: ' + cover.open.outName + '\n'
    yml += '      - switch.turn_off: ' + cover.close.outName + '\n'

    return yml
    console.log('generateCoverInputYaml', yml)
  }


  parseCsv(csv){
    // TODO: Multiple outputs / input: cate un output pe fiecare linie (cala H3)
    // this.ymlOutput=''
    // this.csvRecords.forEach(
    //   (el, i)=>{
    //     if( i > 0){
    //       this.ymlOutput += '\n' + '# ' + el[0].replace('\n', ' | ') + '\n'
    //       this.ymlOutput += '- platform: gpio' + '\n'
    //       // input
    //       let inp = el[1].split('_')
    //       // console.log('inp', inp)
    //       this.ymlOutput += '  name: "ha' + this.adamNo +'_di_' +  inp[2] + '"' +  '\n'
    //       this.ymlOutput += '  pin: ' + '\n'
    //       this.ymlOutput += '    pcf8574: ' + inp[0] + '_hub_in ' +  '\n'
    //       this.ymlOutput += '    number: ' + (inp[2] - 1) +  '\n'
    //       this.ymlOutput += '    mode: INPUT ' +  '\n'
    //       this.ymlOutput += '    inverted: True ' +  '\n'
    //       this.ymlOutput += '  on_' + el[4] + ': ' +  '\n'       // on_press, on click
    //       this.ymlOutput += '    then: ' +  '\n'
    //       this.ymlOutput += '      - switch.toggle: '+ el[3] + ' # ' + el[2] +  '\n'

    //     }
    //   }
    // )
  }

  uploadFiles(files: File[]): Subscription {
    const config = new HttpRequest('POST', this.postUrl, this.myFormData, {
      reportProgress: true
    })

    console.log('Files uploaded', files)

    return this.HttpClient.request( config )
    .subscribe(event=>{
      this.httpEvent = event

      if (event instanceof HttpResponse) {
        alert('upload complete, old school alert used')
      }
    },
    error=>{
      alert('!failure beyond compare cause:' + error.toString())
    })
  }

}
