import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';

import { AddCategoryComponent } from '../categories/add-category/add-category.component';
import { AddProductComponent } from './add-product/add-product.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { EditCategoryComponent } from './../categories/edit-category/edit-category.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  technologies = []
  technology
  products = {}
  categories
  category

  constructor(private db: AngularFirestore, private http: HttpClient, private modalController: ModalController) 
  {
    this.getTechnologies()
   }

  ngOnInit() {}

  getTechnologies(){
    this.db.collection('produse').snapshotChanges()
    .subscribe(
      (res)=>{
        this.technologies=[]
        res.forEach(
          (c)=>{
            let temp = c.payload.doc.data()
            temp['id']= c.payload.doc.id
            this.technologies.push(temp)
          }
        )
        this.technology = this.technologies[0].id
        this.getCategories()
        console.log('[Products] technologies', this.technologies)
      }
    )
  }

  getCategories(){
    this.http.get('https://iotix.herokuapp.com/getCollections/'+this.technology).subscribe(
      (res)=>{
        if (res) {
          this.categories = res
          this.category=res[0]
          this.getProducts()
          }
      },
      (err)=>{}
    )
    
  }

  getProducts(){{}
    console.log('categories', this.categories)
    this.categories.forEach(category => {
      this.products[category] =[]
      this.db.collection('produse').doc(this.technology).collection(category).snapshotChanges()
      .subscribe(
        (res)=>{
          this.products[category]=[]
          res.forEach(
            (c)=>{
              let temp = c.payload.doc.data()
              temp['id']= c.payload.doc.id
              if (temp.id !== 'fake') {
                this.products[category].push(temp) 
              }
            }
          )
          console.log('[Products] products', this.products)
        }
      )
    });
    
  }

  async addCategory(){
    const modal = await this.modalController.create({
      component: AddCategoryComponent,
      componentProps:{
        technology: this.technology
      }
    });
    modal.onDidDismiss().then(
      ()=>{
        console.log('dismissed')
        this.getCategories()
      }
    )
    return await modal.present();
  }

  async addProduct(){
    const modal = await this.modalController.create({
      component: AddProductComponent,
      componentProps:{
        technology: this.technology,
        category: this.category
      }
    });
    return await modal.present();
  }

  deleteProduct(category, id){
    if (confirm('Esti sigur ca vrei sa stergi produsul?'  )) {
      this.db.collection('produse').doc(this.technology).collection(category).doc(id).delete()
      .then(
        (res)=>{
          console.log('Produsul a fost sters', res)
        }
      )
      .catch(
        (err)=>{
          console.log('Produsul nu a putut fi sters', err)
        }
      )
    }
  }

  async editProduct(category, product){
    console.log('Editing product', product)
    const modal = await this.modalController.create({
      component: EditProductComponent,
      componentProps:{
        technology: this.technology,
        category: category,
        product: product
      }
    });
    return await modal.present();
  }

  async editCategory(technology, category){
    console.log('Editing category', category)
    const modal = await this.modalController.create({
      component: EditCategoryComponent,
      componentProps:{
        technology: this.technology,
        category: category
      }
    });
    return await modal.present();
  }

}
