import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
})
export class EditProductComponent implements OnInit {
  @Input() technology
  @Input() category
  @Input() product

  constructor(private db: AngularFirestore, private modalController: ModalController) 
  { }

  ngOnInit() {}

  saveProduct(){
    console.log('Save product', this.product)
    this.db.collection('produse').doc(this.technology).collection(this.category).doc(this.product.id).set(this.product)
    this.modalController.dismiss()
  }

}
