import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss'],
})
export class AddClientComponent implements OnInit {
  client

  constructor( private firestore: AngularFirestore, private modalController: ModalController, private toastController:ToastController) 
  {
    this.client = {
      name: '',
      city:'',
      address: '',
      phone: '',
      email: '',
      company: '',
      cui:'',
      regcom:'',
      iban:'',
      bank:''
    }
   }

  ngOnInit() {}

  saveClient(){
    this.firestore.collection('clienti').add(this.client)
    .then(
      ()=>{
        this.presentToast()
        this.modalController.dismiss()
      }
    )
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Datele clientului au fost salvate.',
      duration: 2000
    });
    toast.present();
  }

}
