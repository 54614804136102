
import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit {

  technology
  category
  product={
    nume: '',
    brand: '',
    pret_achizitie: 0,
    pret_vanzare: 0,
    ore_manopera: 0,
    descriere: ''
  }

  constructor(private db: AngularFirestore, private navParams: NavParams, private modalController: ModalController, private toastController: ToastController) 
  { 
    this.technology = this.navParams.get('technology')
    this.category = this.navParams.get('category')
  }

  ngOnInit() {}


  saveProduct(){
    console.log('[Add product] produse' +  this.technology + ' >> '+  this.category + ' >> ' , this.product)
    this.db.collection('produse').doc(this.technology).collection(this.category).add(this.product)
    .then(
      (res)=>{
        console.log('[Add product] OK', res)
        this.presentToast()
        this.modalController.dismiss()
      }
    )
    .catch(
      (err)=>{
        console.log('[Add product] error', err)
      }
    )
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Produsul a fost salvat.',
      duration: 2000
    });
    toast.present();
  }

  dismissModal(){
    this.modalController.dismiss()
  }
}
