import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
})
export class AddCategoryComponent implements OnInit {
  technology
  category

  constructor(private db: AngularFirestore, private navParams: NavParams, private modalController: ModalController, private toastController: ToastController) 
  { 
    this.technology = this.navParams.get('technology')
  }

  ngOnInit() {}

  saveCategory(){
    let fake = { fake: 'fake'}
    this.db.collection('produse').doc(this.technology).collection(this.category).doc('fake').set(fake)
    .then(
      ()=>{
        this.presentToast()
        this.modalController.dismiss()
      }
    )
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Categoria a fost salvata.',
      duration: 2000
    });
    toast.present();
  }

  dismissModal(){
    this.modalController.dismiss()
  }

}
