import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss'],
})
export class EditCategoryComponent implements OnInit {
  @Input() technology
  @Input() category
  newCategory
  loading = false

  constructor( private modalController: ModalController, private db: AngularFirestore) 
  {  }

  ngOnInit() {
    this.newCategory = this.category
  }

  saveCategory(){
    if(this.newCategory !== this.category){
      console.log('[Edit Category', this.newCategory)
      this.loading = true
      this.moveCollectionData().then(
        (r)=>{
          console.log('data moved', r)
          this.deleteCollectionData().then(
            (r)=>{
              this.loading = false
              alert('Renaming finished!')
              this.modalController.dismiss()
              window.location.reload()
            }
          )
          
        }
      )
    }
    else{
      console.log('[Edit Category', 'Nothing changed!')
    }
  }

  deleteCollectionData(): Promise<any>{
    return new Promise((resolve, reject) => {
      this.db.collection('produse').doc(this.technology).collection(this.category).snapshotChanges()
      .subscribe(
        (res)=>{
          if (res) {
            res.forEach(
              (el, i)=>{
                const tempId= el.payload.doc.id 
                this.db.collection('produse').doc(this.technology).collection(this.category)
                .doc(tempId).delete()
                if(i === res.length - 1){
                  resolve(i)
                }
                else{
                  console.log('Deleted ' + i + ' / ' + res.length)
                }
              }
            )
          }
        })
    })
  }

  moveCollectionData(): Promise<any>{
    return new Promise((resolve, reject) => {
      this.db.collection('produse').doc(this.technology).collection(this.category).snapshotChanges()
      .subscribe(
        (res)=>{
          if (res) {
            res.forEach(
              (el, i)=>{
                const temp = el.payload.doc.data()
                const tempId= el.payload.doc.id 
                this.db.collection('produse').doc(this.technology).collection(this.newCategory)
                .doc(tempId).set(temp)
                if(i === res.length - 1){
                  resolve(i)
                }
                else{
                  console.log('Moved ' + i + ' / ' + res.length)
                }
              }
            )
          }
        })
    })
  }

}
