
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions'
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MenuController, ModalController, NavController } from '@ionic/angular';

import { HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {from} from 'rxjs';
import { Observable } from 'rxjs'
import { switchMap } from "rxjs/operators";

import { OfferDetailsComponent } from './../offer-details/offer-details.component';
import { AddClientComponent } from './../add-client/add-client.component';
import { HomepageComponent } from './../homepage/homepage.component';

@Component({
  selector: 'app-newoffer',
  templateUrl: './newoffer.component.html',
  styleUrls: ['./newoffer.component.scss'],
})
export class NewofferComponent implements OnInit {
  costs = {
    total: 0,
    aquisition_price: 0,
    manopera: 0,
    euro: 0
  }
  technologies = []
  technology
  clients= []
  client
  products = {}
  categories
  category
  offer= {
    tech: 'home_assistant',
    clientId: 1,
    discount: 0,
    locations: [
      {
        name:'zona 1',
        products: 
          [
            {
            category:'',
            product:{},
            quantity: 0
          }
        ],
        scenarios: ''
      }
    ]
  }
  showDiscount = false
  cursEuro
  settings

  constructor(private db: AngularFirestore, private functions: AngularFireFunctions, private http: HttpClient, private menu: MenuController,
     private modalController: ModalController, private navCtrl: NavController) {
    this.getAppSettings()
    this.getTechnologies()
    this.getCursBNR()
   }

  ngOnInit() {
    this.db.collection('clienti').snapshotChanges()
    .subscribe(
      (res)=>{
        this.clients=[]
        res.forEach(
          (c)=>{
            let temp = c.payload.doc.data()
            temp['id']= c.payload.doc.id
            this.clients.push(temp)
          }
        )
        this.client = this.clients[0]
        console.log('[New Offer] Clients', this.clients)
      }
    )
  }

  getAppSettings(){
    this.db.collection('settings').valueChanges()
    .subscribe(
      (res)=>{
        this.settings = res[0]
      }
    )
  }

  getCursBNR() {
    return this.http
      .get("http://www.infovalutar.ro/bnr/azi/eur", { responseType: "text" })
      .subscribe(
        (res)=>{
          this.cursEuro = res
        }
      );
  }
  

  

  changeTechnology(){
    this.offer.locations.forEach(
      (res, i)=>{
        this.offer.locations[i].products= []
      }
    )
  }

  getTechnologies(){
    this.db.collection('produse').snapshotChanges()
    .subscribe(
      (res)=>{
        this.technologies=[]
        res.forEach(
          (c)=>{
            let temp = c.payload.doc.data()
            temp['id']= c.payload.doc.id
            this.technologies.push(temp)
          }
        )
        this.technology = this.technologies[0].id
        this.getCategories()
        console.log('[New Offer] technologies', this.technologies)
      }
    )
  }

  getCategories(){
    this.http.get('https://iotix.herokuapp.com/getCollections/'+this.technology).subscribe(
      (res)=>{
        if (res) {
          this.categories = res
          this.getProducts()
          }
      },
      (err)=>{}
    )
    
  }

  getProducts(){
    console.log('categories', this.categories)
    this.categories.forEach(category => {
      this.products[category] =[]
      this.db.collection('produse').doc(this.technology).collection(category).snapshotChanges()
      .subscribe(
        (res)=>{
          res.forEach(
            (c)=>{
              let temp = c.payload.doc.data()
              temp['id']= c.payload.doc.id
              if (temp.id !== 'fake') {
                this.products[category].push(temp)
              }
            }
          )
          // console.log('[New Offer] products', this.products)
        }
      )
    });
    
  }

  addLocation(){
    this.offer.locations.push(
      {
        name:'',
        products: 
          [
            {
            category:'',
            product:{},
            quantity: 0
          }
        ],
        scenarios: ''
      }
    )
  }

  addProduct(i){
    console.log('Add product', i)
    this.offer.locations[i].products.push(
      {
        category:'',
        product:{},
        quantity: 0
      }
    )
  }

  deleteItem(locationId, productId){
    if( this.offer.locations[locationId].products.length === 1){
      alert('Cannot delete first item!')
      return
    }
    if (confirm('Esti sigur ca vrei sa stergi?') ){
      this.offer.locations[locationId].products.splice(productId, 1)
    }
  }

  calculateCosts(){
    this.costs.total = 0
    this.costs.aquisition_price = 0
    this.costs.manopera = 0
    this.offer.locations.forEach(
      (location)=>{
        location.products.forEach(
          (product)=>{
            this.costs.manopera += product.product['ore_manopera'] * product.quantity * this.settings.manopera_ora
            this.costs.total += ((product.product['pret_vanzare'] * product.quantity) + this.costs.manopera ) * ((100 - this.offer.discount)/100)
            this.costs.aquisition_price += product.product['pret_achizitie'] * product.quantity
          }
        )
      }
    )
    this.costs.euro = this.costs.total / this.cursEuro
  }
  saveOffer(){
    this.offer['client'] = this.client;
    this.offer['costs'] = this.costs;
    this.offer['date'] = new Date()
    this.db.collection('oferte').add(this.offer)
    .then(
      ()=>{
        alert('Oferta a fost salvata')
        this.navCtrl.navigateRoot('/home');
      }
    )
    .catch(
      (err)=>{
        alert('Oferta nu a putut fi salvata ' + err)
      }
    )
  }

  async addClient(){
    const modal = await this.modalController.create({
      component: AddClientComponent
    });
    return await modal.present();
  }

  async showOfferDetails() {
    const modal = await this.modalController.create({
      component: OfferDetailsComponent,
      componentProps: {
        offer: this.offer
      }
    });
    return await modal.present();
  }

  addValue(){
    this.offer.discount++
    this.calculateCosts()
  }
  
  substractValue(){
    this.offer.discount--
    this.calculateCosts()
  }

  displayDiscount(value){
    this.showDiscount = value
  }

}
