import { ToolsComponent } from './tools/tools.component'
import { NgModule } from '@angular/core'
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { NgxCsvParserModule } from 'ngx-csv-parser'
import { ZXingScannerModule } from '@zxing/ngx-scanner'
import { NgxKjuaModule } from 'ngx-kjua';

import { AngularFireModule } from '@angular/fire'
import { AngularFireFunctionsModule, ORIGIN, NEW_ORIGIN_BEHAVIOR } from '@angular/fire/functions'
import { USE_EMULATOR as FUNCTIONS_EMULATOR } from '@angular/fire/functions'
import { ngfModule } from 'angular-file'

import { PlusMinusComponent } from './components/plus-minus/plus-minus.component'

import { environment } from './../environments/environment'
import { NewofferComponent } from './newoffer/newoffer.component'
import { HomepageComponent } from './homepage/homepage.component'
import { OffersComponent } from './offers/offers.component'
import { AddClientComponent } from './add-client/add-client.component'
import { OfferDetailsComponent } from './offer-details/offer-details.component'
import { ProductsComponent } from './products/products.component'
import { AddProductComponent } from './products/add-product/add-product.component'
import { AddCategoryComponent } from './categories/add-category/add-category.component'
import { EditProductComponent } from './products/edit-product/edit-product.component'
import { EditCategoryComponent } from './categories/edit-category/edit-category.component'
import { EditOfferComponent } from './offers/edit-offer/edit-offer.component'
import { YamlGeneratorComponent } from './tools/yaml-generator/yaml-generator.component'
import { InventoryComponent } from './inventory/inventory.component'
import { QrscannerComponent } from './inventory/qrscanner/qrscanner.component'
import { QrgeneratorComponent } from './inventory/qrgenerator/qrgenerator.component'
import { from } from 'rxjs'



@NgModule({
  declarations: [AppComponent,
    OffersComponent,
    NewofferComponent,
    HomepageComponent,
    AddClientComponent,
    OfferDetailsComponent,
    ProductsComponent,
    AddProductComponent,
    AddCategoryComponent,
    EditProductComponent,
    EditCategoryComponent,
    EditOfferComponent,
    ToolsComponent,
    YamlGeneratorComponent,
    InventoryComponent, QrscannerComponent, QrgeneratorComponent
  ],
  entryComponents: [
    AddProductComponent,
    AddCategoryComponent,
    YamlGeneratorComponent
  ],
  imports: [BrowserModule,
    CommonModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    AngularFireFunctionsModule,
    HttpClientModule,
    ngfModule,
    NgxCsvParserModule,
    AngularFireModule.initializeApp(environment.firebase),
    ZXingScannerModule, NgxKjuaModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NEW_ORIGIN_BEHAVIOR, useValue: true },
  //  { provide: ORIGIN, useValue: 'http://iotix-d55f1.web.app' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
