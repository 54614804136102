// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyC5JGwJJpOST6DAlUR97vgUbO0VJINZuTE",
    authDomain: "iotix-d55f1.firebaseapp.com",
    projectId: "iotix-d55f1",
    storageBucket: "iotix-d55f1.appspot.com",
    messagingSenderId: "946126003211",
    appId: "1:946126003211:web:41c767b9ac53aab9007b0e",
    measurementId: "G-ZP4F8CMQMW"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
