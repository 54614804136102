import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as CryptoJS from 'crypto-js';

import { QrscannerComponent } from './qrscanner/qrscanner.component';
import { QrgeneratorComponent } from './qrgenerator/qrgenerator.component';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent implements OnInit {

  result

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  async scanQR(){
    const qrModal = await this.modalCtrl.create({
      component: QrscannerComponent,
      // componentProps: { project: this.currentProject, assignedTo: this.currentUserAdditionalData.name, assignedToId: this.currentUserAdditionalData.id }
    })
    qrModal.present()
    qrModal.onDidDismiss().then(
      (res) => {
        console.log('Qr scan result', res)
        const jsonRes = JSON.parse(res.toString())
        const resData = CryptoJS.AES.decrypt(JSON.stringify(jsonRes['data']), 'secret123IoTix')
        console.log('resData', resData)
        this.result = resData
        alert(res)
      }
    )
  }

  async generateQR(){
    const qrModal = await this.modalCtrl.create({
      component: QrgeneratorComponent
    })
    qrModal.present()
    qrModal.onDidDismiss().then(
      (res) => {
        console.log('Qr scan result', res)
        alert(res)
      }
    )
  }

}
