import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss'],
})
export class OfferDetailsComponent implements OnInit {
  @Input() offer

  costs = {
    total: 0,
    aquisition_price: 0,
    manopera: 0
  }
  locations: 0
  products: 0
  settings

  constructor( private modalController: ModalController, private db: AngularFirestore) 
  { 
    this.getAppSettings()
  }

  ngOnInit() {

    this.costs.total = 0
    this.costs.aquisition_price = 0
    this.locations = 0
    this.products =0

    this.offer.locations.forEach(
      (location)=>{
        this.locations++
        location.products.forEach(
          (product)=>{
            this.products += product.quantity
            console.log('Manopera ora', this.settings)
            this.costs.manopera += product.product['ore_manopera'] * product.quantity  * this.settings.manopera_ora
            
            this.costs.total += ((product.product['pret_vanzare'] * product.quantity) + this.costs.manopera ) * ((100 - this.offer.discount)/100)
            this.costs.aquisition_price += product.product['pret_achizitie'] * product.quantity
          }
        )
      }
    )
  }

  getAppSettings(){
    this.db.collection('settings').valueChanges()
    .subscribe(
      (res)=>{
        this.settings = res[0]
      }
    )
  }

  dismissModal(){
    this.modalController.dismiss()
  }

}
