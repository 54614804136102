import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-qrscanner',
  templateUrl: './qrscanner.component.html',
  styleUrls: ['./qrscanner.component.scss'],
})
export class QrscannerComponent implements OnInit {

  constructor( private modalCtrl: ModalController) { }

  ngOnInit() {}

  scanSuccess($event){
    console.log('[QR scanner component] {scanSuccess} ', $event)
    this.modalCtrl.dismiss($event)
  }

}
