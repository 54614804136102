import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-qrgenerator',
  templateUrl: './qrgenerator.component.html',
  styleUrls: ['./qrgenerator.component.scss'],
})
export class QrgeneratorComponent implements OnInit {
  QRdata
  QRtext = ''
  inventoryId
  clientId

  constructor() { }

  ngOnInit() {}

  inputChange(){
    this.QRdata = {
      sku: this.inventoryId,
      client: this.clientId
    }
    this.QRtext = JSON.stringify(this.QRdata)

    this.QRtext = 'website: www.iotix.ro, data: ' +   encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(this.QRdata), 'secret123IoTix').toString());
  }

}
